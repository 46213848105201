const BaseApi = process.env.REACT_APP_BASE_API;

const Apis = {
	CATEGORIES: `${BaseApi}/app/categories`,
	MY_PROFILE: `${BaseApi}/app/my-profile`,
	SIGNIN: `${BaseApi}/signin-linkedin`,
	LOGOUT: `${BaseApi}/app/logout`,
	ADVISORS: `${BaseApi}/app/advisors`,
	REVIEWS: `${BaseApi}/app/reviews`,
	MY_HISTORIES: `${BaseApi}/app/my-histories`,
	MY_REVIEWS: `${BaseApi}/app/my-reviews`,
	MY_MESSAGES: `${BaseApi}/app/my-messages`,
	SEND_MESSAGE: `${BaseApi}/app/message`,
	ADD_REVIEW: `${BaseApi}/app/review`,
	SELECT_PLAN: `${BaseApi}/app/select-plan`,
	ADD_LOCATION: `${BaseApi}/app/locations`,
	LOCATIONS: `${BaseApi}/app/locations`,
	COMPANY_LOCATIONS: `${BaseApi}/app/company-locations`,
	LOCATION: `${BaseApi}/app/locations`,
	DELETE_LOCATION: `${BaseApi}/app/locations`,
	MAKE_PRIMARY_LOCATION: `${BaseApi}/app/make-primary-location`,
	ADD_USER: `${BaseApi}/app/users`,
	USERS: `${BaseApi}/app/users`,
	DELETE_USER: `${BaseApi}/app/users`,
	USER: `${BaseApi}/app/users`,
	UPLOAD_DOCUMENTS: `${BaseApi}/app/upload-documents`,
	ADD_DOCUMENT: `${BaseApi}/app/documents`,
	ADD_EMPLOYEE_DOCUMENT: `${BaseApi}/app/add-employee-document`,
	GET_EMPLOYEE_DOCUMENT: `${BaseApi}/app/get-employee-document`,
	EMPLOYEE_DOCUMENT_STATUS: `${BaseApi}/app/employee-document`,
	CHECK_EMPLOYEE_DOCUMENT_ACTION_TOKEN: `${BaseApi}/app/employee-document/check-token`,
	GET_EMPLOYEE_DOCUMENT_REVIEW_DATA: `${BaseApi}/app/get-employee-document-review-data`,
	DOCUMENTS: `${BaseApi}/app/documents`,
	MULTIPLE_DOCUMENTS: `${BaseApi}/app/multiple-documents`,
	MULTIPLE_EQUIPMENTS: `${BaseApi}/app/multiple-equipments`,
	ADD_TRANSFER_OWNERSHIP: `${BaseApi}/app/transferownerships`,
	TRANSFER_OWNERSHIPS: `${BaseApi}/app/transferownerships`,
	PAYMET_METHODS: `${BaseApi}/app/payment-methods`,
	PAYMET_ACCOUNTS: `${BaseApi}/app/payment-accounts`,
	SUBSCRIPTIONS: `${BaseApi}/app/subscriptions`,
	ADD_COMPANY: `${BaseApi}/app/companies`,
	GET_COMPANY: `${BaseApi}/app/company`,
	GET_BILLING_DETAILS: `${BaseApi}/app/billing-details`,
	UPDATE_USER: `${BaseApi}/app/users`,
	UPDATE_LOCATION: `${BaseApi}/app/locations`,
	EQUIPMENTS: `${BaseApi}/app/equipments`,
	ADD_EQUIPMENT: `${BaseApi}/app/equipments`,
	NUMBER_OF_DOCUMENTS: `${BaseApi}/app/numberOfDocuments`,
	NUMBER_OF_USERS_AND_LOCATIONS: `${BaseApi}/app/numberOfUsersandLocations`,
	ADD_CREDIT_CARD: `${BaseApi}/app/creditCards`,
	ADDITIONAL_CREDIT_CARD: `${BaseApi}/app/additional-credit-card`,
	DELETE_CREDIT_CARD: `${BaseApi}/app/delete-credit-card`,
	NOTIFICATIONS_OF_FOLDER: `${BaseApi}/app/notifications/folder`,
	NOTIFICATIONS_OF_LOCATION: `${BaseApi}/app/notifications/location`,
	NOTIFICATIONS_OF_COMPANY: `${BaseApi}/app/notifications/company`,
	DELETE_DOCUMENT: `${BaseApi}/app/documents`,
	DELETE_EQUIPMENT: `${BaseApi}/app/equipments`,
	EQUIPMENT: `${BaseApi}/app/equipment`,
	UPDATE_EQUIPMENT: `${BaseApi}/app/equipment`,
	DOCUMENT: `${BaseApi}/app/document`,
	UPDATE_DOCUMENT: `${BaseApi}/app/document`,
	SIGNUP_WITH_EMAIL: `${BaseApi}/app/signup`,
	VERIFY_CODE: `${BaseApi}/app/verify-code`,
	RESEND_CODE: `${BaseApi}/app/resend-code`,
	SIGNIN_EMAIL: `${BaseApi}/app/signin`,
	FORGET_PASSWORD: `${BaseApi}/app/forget-password`,
	CHANGE_PASSWORD: `${BaseApi}/app/change-password`,
	COMPLETE_ACTION: `${BaseApi}/app/complete-action`,
	ACCESS_HISTORY: `${BaseApi}/app/access-history`,
	ADD_CATEGORY_FOLDER: `${BaseApi}/app/add-category-folder`,
	ADD_FOLDER: `${BaseApi}/app/add-folder`,
	GET_CATEGORY_FOLDERS: `${BaseApi}/app/get-category-folders`,
	GET_CATEGORY_LABELS: `${BaseApi}/app/get-category-labels`,
	GET_FOLDER: `${BaseApi}/app/get-folder`,
	DELETE_CATEGORY_FOLDER: `${BaseApi}/app/delete-category-folder`,
	DELETE_FOLDER: `${BaseApi}/app/delete-folder`,
	SEARCH_FOLDERS: `${BaseApi}/app/search-folders`,
	CATEGORY_ORDER: `${BaseApi}/app/category-order`,
	SOFT_DELETE_ACTION: `${BaseApi}/app/soft-delete-action`,
	RESTORE_DELETED_ACTION: `${BaseApi}/app/restore-deleted-action`,
	VOID_ACTION: `${BaseApi}/app/void-action`,
	MOVE_FOLDER: `${BaseApi}/app/move-folder`,
	MOVE_FILE: `${BaseApi}/app/move-file`,
	GET_NUMBER_OF_CATEGORY_FILES: `${BaseApi}/app/number-of-category-files`,
	GET_NUMBER_OF_FOLDER_FILES: `${BaseApi}/app/number-of-folder-files`,
	GET_CATEGORY_FOLDER: `${BaseApi}/app/get-catefory-folder`,
	EDIT_CATEGORY_FOLDER: `${BaseApi}/app/edit-category-folder`,
	EDIT_FOLDER: `${BaseApi}/app/edit-folder`,
	ADD_EMPLOYEE: `${BaseApi}/app/add-employee`,
	EDIT_EMPLOYEE: `${BaseApi}/app/edit-employee`,
	EDIT_EMPLOYEE_BY_CATEGORY: `${BaseApi}/app/edit-employee-by-category`,
	GET_ALL_EMPLOYEES: `${BaseApi}/app/get-employees`,
	GET_EMPLOYEE_BY_CATEGORY: `${BaseApi}/app/get-employee-by-category`,
	MAKE_CREDIT_CARD_PRIMARY: `${BaseApi}/app/credit-card-primary`,

	CANCEL_TRANFER_LOCATION_OWNERSHIP: `${BaseApi}/app/transferownerships`,
	LOCATIONS_OF_TRANSFER: `${BaseApi}/app/transferownerships`,
	USERS_OF_LOCATION: `${BaseApi}/app/transferownerships`,
	TRANSFER_ACCOUNT_OWNERSHIP: `${BaseApi}/app/transferownerships`,
	ADD_PAYMENT_METHOD: `${BaseApi}/app/transferownerships`,
	ADD_BACKUP_PAYMENT_METHOD: `${BaseApi}/app/transferownerships`,
	UPDATE_PAYMENT_METHOD: `${BaseApi}/app/transferownerships`,
	DELETE_SUBSCRIPTION: `${BaseApi}/app/transferownerships`,
	UPGRADE_SUBSCRIPTION: `${BaseApi}/app/transferownerships`,
	DOWNGRADE_SUBSCRIPTION: `${BaseApi}/app/transferownerships`,
	REFERRALS: `${BaseApi}/app/transferownerships`,
	REFER_A_COLLEAGUE: `${BaseApi}/app/transferownerships`,
	COUNTRIES: `${BaseApi}/app/transferownerships`,
	CANCEL_SUBSCRIPTION: `${BaseApi}/app/cancel-subscription`,
	CANCEL_USER_SUBSCRIPTION: `${BaseApi}/app/cancel-user-subscription`,
	UPDATE_SUBSCRIPTION: `${BaseApi}/app/update-subscription`,
	CHANGE_ROLE: `${BaseApi}/app/change-role`,
};
export { Apis, BaseApi };
