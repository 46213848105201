import { FilterActionStatusType, IFilterType } from 'src/interfaces';
import { DocumentRequestStatus } from './enums';

export const filterStatusList: Array<FilterActionStatusType> = [
	DocumentRequestStatus.SubmissionRejected as const,
	DocumentRequestStatus.ApprovalRequired as const,
	DocumentRequestStatus.Delinquent as const,
];

export const filterList: Array<IFilterType> = [
	'All',
	'Pending',
	'Resolved',
	'Approved',
	'Rejected',
	'Voided',
	'Archived',
];
