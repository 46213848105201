// import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const fetchService: ({
	token,
	url,
	method,
	addContentTypeApplicationJson,
	body,
	formData,
	showSuccessMessage,
	showErrorMessage,
}: {
	token?: string;
	url: string;
	method?: string;
	addContentTypeApplicationJson?: boolean;
	body?: any;
	formData?: any;
	showSuccessMessage?: boolean;
	showErrorMessage?: boolean;
	queryParams?: Record<string, string>;
}) => Promise<{ data: any; status: number }> = async ({
	token = '',
	url,
	method = 'GET',
	addContentTypeApplicationJson = false,
	body,
	formData,
	showSuccessMessage = false,
	showErrorMessage = true,
	queryParams,
}) => {
	let status: number;
	let fetchBody: any;
	const headers: any = {};

	if (token) {
		headers['Authorization'] = token;
	}
	if (addContentTypeApplicationJson) {
		headers['Content-Type'] = 'application/json';
	}
	if (body) {
		fetchBody = JSON.stringify(body);
	} else if (formData) {
		fetchBody = formData;
	}

	try {
		if (queryParams) {
			url = `${url}?${new URLSearchParams(queryParams)}`;
		}

		const response = await fetch(url, {
			method: method,
			headers: headers,
			body: fetchBody,
		});

		status = response.status;

		const responseJson = await response.json();

		if (status === 401) {
			// Cookies.remove('token', {
			// 	domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
			// });
			toast.error(responseJson.message);
			// window.location.href = `${process.env.REACT_APP_PERLA_LINK}`|| '';
			return { status: 401, data: {} };
		} else if (status === 400) {
			toast.error(responseJson.message);
			return { status: status, data: responseJson };
		} else if (status >= 200 && status < 300) {
			if (showSuccessMessage) {
				toast.success(responseJson.message);
			}
			return { status: status, data: responseJson };
		} else {
			if (showErrorMessage) {
				toast.error(responseJson.message);
			}
			return { status: status, data: responseJson };
		}
	} catch (error: any) {
		toast.error(error.message || 'Network Error!');

		return { status: 500, data: {} };
	}
};

export { fetchService };
