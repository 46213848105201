import { lazy } from 'react';
import { UserRole } from './enums';

export const routes = [
	{
		path: '/',
		component: lazy(() => import('../routes/mainPage/mainPage')),
		exact: true,
	},
	// {
	// 	path: '/',
	// 	component: lazy(() => import('../routes/home/Home')),
	// 	exact: true,
	// },
	// {
	// 	path: '/services',
	// 	component: lazy(() => import('../routes/services/Services')),
	// },
	// {
	// 	path: '/location',
	// 	component: lazy(() => import('../routes/location/Location')),
	// },
	// {
	// 	path: '/search',
	// 	component: lazy(() => import('../routes/search/Search')),
	// },
	// {
	// 	path: '/search-again',
	// 	component: lazy(() => import('../routes/searchAgain/SearchAgain')),
	// },
	// {
	// 	path: '/advisor/:advisorId/contact',
	// 	component: lazy(() => import('../routes/contact/Contact')),
	// },
	// {
	// 	path: '/advisor/:advisorId/add-review',
	// 	component: lazy(() => import('../routes/addReview/AddReview')),
	// },
	// {
	// 	path: '/advisor/:advisorId',
	// 	component: lazy(() => import('../routes/advisor/AdvisorRoute')),
	// },
	{
		path: '/linkedin',
		component: lazy(() => import('../routes/linkedin/Linkedin')),
	},
	{
		path: '/signin/client',
		component: lazy(() => import('../routes/signin/SigninAsClient')),
		exact: true,
	},
	{
		path: '/signin/client/signin-with-email',
		component: lazy(
			() => import('../routes/signin/signinWithEmail/signinWithEmail'),
		),
	},
	{
		path: '/signin/client/signup-with-email',
		component: lazy(
			() => import('../routes/signin/signupWithEmail/signupWithEmail'),
		),
	},
	{
		path: '/signin/client/verify-code/:userId/:type?',
		component: lazy(() => import('../routes/signin/VerifyCode/verifyCode')),
	},
	{
		path: '/signin/client/forgert-password',
		component: lazy(
			() => import('../routes/signin/ForgetPassword/ForgetPassword'),
		),
	},
	{
		path: '/signin/client/change-password/:operationId',
		component: lazy(
			() => import('../routes/signin/ChangePassword/ChangePassword'),
		),
	},
	// {
	// 	path: '/client/reviews',
	// 	component: lazy(() => import('../routes/client/Reviews')),
	// },
	// {
	// 	path: '/client/account',
	// 	component: lazy(() => import('../routes/client/Account')),
	// },
	{
		path: '/business-suite/edit-document/:documentId',
		component: lazy(
			() => import('../routes/businessSuite/Document/EditDocument'),
		),
		exact: true,
	},
	{
		path: '/business-suite/equipment-device-documents/:locationId/:folderId',
		component: lazy(
			() =>
				import(
					'../routes/businessSuite/EquipmentDeviceDocument/EquipmentDeviceDocument'
				),
		),
		exact: true,
	},
	{
		path: '/business-suite/add-equipment/:folderId',
		component: lazy(
			() =>
				import(
					'../routes/businessSuite/EquipmentDeviceDocument/AddEquipment/AddEquipment'
				),
		),
		exact: true,
	},
	{
		path: '/business-suite/edit-equipment/:equipmentId',
		component: lazy(
			() =>
				import(
					'../routes/businessSuite/EquipmentDeviceDocument/EditEquipment/EditEquipment'
				),
		),
		exact: true,
	},
	{
		path: '/business-suite/documents/:locationId/:folderId/:categoryType',
		component: lazy(() => import('../routes/businessSuite/Document/Document')),
		exact: true,
	},
	{
		path: '/business-suite/notifications/:locationId/:type?/:folderId?',
		component: lazy(() => import('../routes/businessSuite/Notifications')),
		exact: true,
	},
	{
		path: '/business-suite/company-notifications',
		component: lazy(
			() =>
				import('../routes/businessSuite/Notifications/CompanyNotifications'),
		),
		exact: true,
	},
	{
		path: '/business-suite/add-document/:folderId/:categoryType?',
		component: lazy(
			() => import('../routes/businessSuite/Document/AddDocument/AddDocument'),
		),
		exact: true,
	},
	{
		path: '/business-suite/:pathLocationId?/:type?/:scrollHeight?',
		component: lazy(() => import('../routes/businessSuite/Dashboard')),
		exact: true,
	},

	{
		path: '/personal',
		component: lazy(() => import('../routes/personal/Personal')),
	},
	{
		path: '/signin/advisor',
		component: lazy(() => import('../routes/signin/SigninAsAdvisor')),
	},
	{
		path: '/signin',
		component: lazy(() => import('../routes/signin/Signin')),
	},
	{
		path: '/cliam-user-dashboard',
		component: lazy(() => import('../routes/signin/ClaimYourUserDashboard')),
	},
	{
		path: '/verify-code',
		component: lazy(() => import('../routes/signin/VerifyCode')),
	},
	{
		path: '/claimed-suucessfully',
		component: lazy(() => import('../routes/signin/SuccessfullyClaimed')),
	},
	{
		path: '/admin-console',
		component: lazy(() => import('../routes/adminConsole/adminConsole')),
		roles: [UserRole.SuperAdmin, UserRole.Admin],
		exact: true,
	},
	{
		path: '/admin-console/manage-users',
		component: lazy(
			() => import('../routes/adminConsole/users/manageUsers/manageUsers'),
		),
		roles: [UserRole.SuperAdmin, UserRole.Admin],
		exact: true,
	},
	{
		path: '/admin-console/manage-users/view-access-history/:userId',
		component: lazy(
			() =>
				import(
					'../routes/adminConsole/users/viewAccessHistory/viewAccessHistory'
				),
		),
		roles: [UserRole.SuperAdmin, UserRole.Admin],
	},
	{
		path: '/admin-console/manage-users/user-information/:userId?',
		component: lazy(
			() =>
				import('../routes/adminConsole/users/userInformation/userInformation'),
		),
		roles: [UserRole.SuperAdmin, UserRole.Admin],
	},
	{
		path: '/admin-console/manage-locations',
		component: lazy(
			() =>
				import(
					'../routes/adminConsole/locations/manageLocations/manageLocations'
				),
		),
		roles: [UserRole.SuperAdmin],
		exact: true,
	},
	{
		path: '/admin-console/manage-locations/location-information/:locationId?',
		component: lazy(
			() =>
				import(
					'../routes/adminConsole/locations/locationInformation/locationInformation'
				),
		),
		roles: [UserRole.SuperAdmin],
	},
	{
		path: '/admin-console/transfer-location-ownership',
		component: lazy(
			() =>
				import(
					'../routes/adminConsole/transferOwnership/transferLocationOwnership/transferLocationOwnership'
				),
		),
		roles: [UserRole.SuperAdmin],
	},
	{
		path: '/admin-console/list-of-transfers',
		component: lazy(
			() =>
				import(
					'../routes/adminConsole/transferOwnership/listOftransfers/listOftransfers'
				),
		),
		roles: [UserRole.SuperAdmin],
	},
	{
		path: '/admin-console/list-of-referrals',
		component: lazy(
			() =>
				import(
					'../routes/adminConsole/referAcolleague/listOfReferrals/listOfReferrals'
				),
		),
		roles: [UserRole.SuperAdmin, UserRole.Admin],
	},
	{
		path: '/admin-console/refer-a-colleague',
		component: lazy(
			() =>
				import(
					'../routes/adminConsole/referAcolleague/referAcolleague/referAColleague'
				),
		),
		roles: [UserRole.SuperAdmin, UserRole.Admin],
	},
	{
		path: '/admin-console/billing',
		component: lazy(
			() => import('../routes/adminConsole/billing/manage/billing'),
		),
		roles: [UserRole.SuperAdmin],
		exact: true,
	},
	{
		path: '/admin-console/billing/payment-methods',
		component: lazy(
			() =>
				import('../routes/adminConsole/billing/paymentMethods/paymentMethods'),
		),
		roles: [UserRole.SuperAdmin],
	},
	{
		path: '/admin-console/billing/update-subscription',
		component: lazy(
			() => import('../routes/adminConsole/pricing/UpdateSubscription'),
		),
		roles: [UserRole.SuperAdmin],
	},
	{
		path: '/how-it-works',
		component: lazy(
			() => import('../routes/footerRoutes/howItWorks/howItWorks'),
		),
	},
	{
		path: '/how-it-works-advisor',
		component: lazy(
			() =>
				import('../routes/footerRoutes/howItWorksAdvisor/howItWorksAdvisor'),
		),
	},
	{
		path: '/medical-business-suite-application',
		component: lazy(
			() =>
				import(
					'../routes/footerRoutes/medicalBusinessSuite/medicalBusinessSuite'
				),
		),
	},
	{
		path: '/plans',
		component: lazy(() => import('../routes/footerRoutes/pricing/pricing')),
	},
	{
		path: '/add-company',
		component: lazy(() => import('../routes/addCompany/AddCompany')),
		roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Edit],
	},
	{
		path: '/add-credit-card',
		component: lazy(() => import('../routes/creditCard/AddCreditCard')),
		roles: [UserRole.SuperAdmin, UserRole.Admin, UserRole.Edit],
	},
	{
		path: '/request-message',
		component: lazy(
			() => import('../routes/creditCard/RequestMessage/RequestMessage'),
		),
	},
	{
		path: '/verified-message',
		component: lazy(
			() => import('../routes/creditCard/VerifiedMessage/VerifiedMessage'),
		),
	},
	{
		path: '/employee-document/:token',
		component: lazy(
			() =>
				import(
					'../routes/employeeDocument/addEmployeeDocument/AddEmployeeDocument'
				),
		),
		exact: true,
	},
	{
		path: '/employee-document-review/:token',
		component: lazy(
			() =>
				import(
					'../routes/employeeDocument/reviewEmployeeDocument/ReviewEmployeeDocument'
				),
		),
		roles: [UserRole.SuperAdmin, UserRole.Admin],
		exact: true,
	},
];
