import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const AddCreditCardService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_CREDIT_CARD,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const AdditionalCardService: (
	token: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: Apis.ADDITIONAL_CREDIT_CARD,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const DeleteCreditCardService: (
	token: string,
	cardId: string,
) => Promise<{ data: any; status: number }> = async (token, cardId) => {
	return fetchService({
		url: `${Apis.DELETE_CREDIT_CARD}?cardId=${cardId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};
const MakeCreditCardPrimaryService: (
	token: string,
	cardId: string,
) => Promise<{ data: any; status: number }> = async (token, cardId) => {
	return fetchService({
		url: `${Apis.MAKE_CREDIT_CARD_PRIMARY}/${cardId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	AddCreditCardService,
	AdditionalCardService,
	DeleteCreditCardService,
	MakeCreditCardPrimaryService,
};
