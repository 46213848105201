export const fileSizeLimit = 10 * 1024 * 1024; // 10 Megabytes

export const acceptedFormats = {
	'application/msword': ['.doc', '.docx'],
	'application/pdf': ['.pdf'],
	'application/vnd.ms-excel': ['.xls'],
	'image/jpeg': ['.jpg', '.jpeg'],
	'image/x-png': ['.png'],
	'image/heic': ['.heic'],
	'image/heif': ['.heif'],
	'application/octet-stream': ['.heic', '.heif'],
	'application/vnd.ms-powerpoint': ['.ppt'],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
		'.xls',
		'.xlsx',
		'.csv',
	],
	'text/csv': ['.csv'],
};

export const acceptedFormatsText =
	'PDF, JPG, PNG, HEIC, Word, Excel, Power Point';

export const detailFilesOptions = ['Show Details', 'Hide Details'];
