import { ActionStatus, DocumentRequestStatus } from 'src/constants';

export const isGrayedOut = (item: any) => {
	if (
		item.status === ActionStatus.Completed ||
		item.status === ActionStatus.Voided ||
		item.documentRequestStatus === DocumentRequestStatus.SubmissionApproved ||
		item.documentRequestStatus === DocumentRequestStatus.SubmissionRejected
	) {
		return true;
	}

	return false;
};
