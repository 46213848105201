export const documentInitialValues = {
	document_name: '',
	document_status: '',
	document_type: '',
	effective_date: '',
	term_or_duration: '',
	termination_date: '',
	notice_requirement: '',
	// TODO: attestation agreement are temporarily disabled
	// attestation_agreement: true,
	pocs: [
		{
			first_name: '',
			last_name: '',
			company_name: '',
			address: '',
			city: '',
			state: '',
			zip_code: '',
			telephone: '',
			email: '',
			website_url: '',
		},
	],
	parties: [
		{
			party_type: '',
			legal_name: '',
			business_name: '',
			street_address: '',
			city: '',
			zip_code: '',
			state: '',
			website_url: '',
			telephone: '',
			email: '',
		},
	],
	compensations: [
		{
			sign: '$',
			value: '',
			value_description: '',
		},
	],
	transferability: {
		transferability_type: '',
		parties: [{ party_name: '', party_type: '' }],
	},
	uploaded_files: [],
	uploaded_files_size: 0,
	baa: {
		baa_is_required: '',
		parties: [''],
	},
	actions: [],
	folder: '',
	type: '',
	actionsError: '',
	restrictive_covenants: [],
	dispute_resolution: {},
	email_of_company: '',
	note: '',
};
