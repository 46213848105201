import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetMyProfile: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async token => {
	return fetchService({
		url: Apis.MY_PROFILE,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetAllUsersService: (
	token: string,
	locationId?: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token, locationId = '') => {
	return fetchService({
		url: Apis.USERS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetUserService: (
	userId: string,
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async (userId, token) => {
	return fetchService({
		url: Apis.USER + '/' + userId,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const AddUserService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_USER,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const UpdateUserService: (
	userId: number,
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (userId, body, token) => {
	return fetchService({
		url: `${Apis.UPDATE_USER}/${userId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: true,
		token: token,
	});
};

const UpdateUserLicenseStatusService: (
	userId: number,
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (userId, body, token) => {
	return fetchService({
		url: `${Apis.UPDATE_USER}/${userId}/license-status`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: true,
		token: token,
	});
};

const DeleteUserService: (
	{ userId, newAdminId }: { userId: string, newAdminId: string | undefined },
	token: string,
) => Promise<{ data: any; status: number }> = async ({ userId, newAdminId }, token) => {
	const queryParams = {
		userId,
		newAdminId: newAdminId ? newAdminId : '',
	};

	return fetchService({
		url: `${Apis.DELETE_USER}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
		queryParams,
	});
};

const UpdateUserRoleService: (
	token: string,
	userId: number,
	role: string,
) => Promise<{ data: any; status: number }> = async (token, userId, role) => {
	return fetchService({
		url: `${Apis.CHANGE_ROLE}/${userId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: { role },
		showSuccessMessage: false,
		token: token,
	});
};

const GetUserAccessHistoryService: (
	token: string,
	userId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token, userId) => {
	return fetchService({
		url: Apis.ACCESS_HISTORY + '/' + userId,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetNumberOfUsersAndLocationsOfUserService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async token => {
	return fetchService({
		url: Apis.NUMBER_OF_USERS_AND_LOCATIONS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	GetAllUsersService,
	GetUserService,
	AddUserService,
	UpdateUserService,
	UpdateUserLicenseStatusService,
	DeleteUserService,
	UpdateUserRoleService,
	GetUserAccessHistoryService,
	GetNumberOfUsersAndLocationsOfUserService,
	GetMyProfile,
};
