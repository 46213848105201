import { Apis } from 'src/constants/Apis';
import { fetchService } from './service';

const ApproveOrRejectEmployeeDocument: (
	token: string,
	type: 'approve' | 'reject',
	body: any,
) => Promise<{ data: any; status: number }> = async (token, type, body) => {
	return fetchService({
		url: `${Apis.EMPLOYEE_DOCUMENT_STATUS}/${type}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		token,
		body,
	});
};

const GetEmployeeDocumentService: (
	employeeDocumentToken: string,
) => Promise<{ data: any; status: number }> = async employeeDocumentToken => {
	return fetchService({
		url: `${Apis.GET_EMPLOYEE_DOCUMENT}/${employeeDocumentToken}`,
		method: 'GET',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		showErrorMessage: false,
	});
};

export { ApproveOrRejectEmployeeDocument, GetEmployeeDocumentService };
