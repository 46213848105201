import Cookies from 'js-cookie';
import {
	ReactElement,
	Suspense,
	useCallback,
	useEffect,
	useState,
} from 'react';
import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Footer from './common/Footer/Footer';
import Header from './common/Header/Header';
import RouteLoader from './components/routeLoader/RouteLoader';
import {
	resetLogedInUserState,
	selectLogedInUserInfo,
	setProfile,
} from './features/logedInUser/logedInUserSlice';
import { resetAllSearch } from './features/search/searchSlice';
import { GetMyProfile, LogoutService } from './services';

import { routes } from './constants';
import { checkRoleAccess } from './utils';

import { createTheme, MuiThemeProvider } from '@material-ui/core';
import perlaLogo from './common/assets/images/Logo.svg';

const materialTheme = createTheme({
	typography: {
		fontFamily: 'LotaGrotesque',
	},
});

function App(): ReactElement {
	const logedInUser = useAppSelector(selectLogedInUserInfo);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const token = Cookies.get('token') || '';

		if (
			(logedInUser.token && logedInUser.token.length > 0) ||
			token.length > 0
		) {
			GetMyProfile(
				logedInUser.token && logedInUser.token.length > 0
					? logedInUser.token
					: token,
			).then(({ data, status }) => {
				if (status === 200) {
					if (data.user) {
						const userInfo = {
							avatar: data.user.photo ? data.user.photo : '',
							email: data.user.email,
							firstName: data.user.first_name,
							lastName: data.user.last_name,
							status: data.user.status,
							token: token,
							isAdvisor: data.user.isAdvisor,
							company: data.user.company,
							enableReview: data.user.enableReview,
							role: data.user.role,
							id: data.user._id,
							categoryPermissions: data.user.categoryPermissions,
						};
						dispatch(setProfile(userInfo));
					} else {
						localStorage.clear();
						setLoading(false);
						Cookies.remove('token', {
							domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
						});
						window.location.href = `${process.env.REACT_APP_PERLA_LINK}` || '';
					}
				} else {
					window.location.href = `${process.env.REACT_APP_PERLA_LINK}` || '';
				}
				setLoading(false);
			});
		} else {
			localStorage.clear();
			Cookies.remove('token', {
				domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
			});
			if (!window.location.pathname.startsWith('/employee-document/')) {
				window.location.href = process.env.REACT_APP_PERLA_LINK || '';
			}
			setLoading(false);
		}
	}, [dispatch, logedInUser.token]);

	useEffect(() => {
		if (logedInUser?.company?.verified_by_perla === 'require_credit_card') {
			window.location.href = `${process.env.REACT_APP_LOGIN_PERLA_LINK}plans`;
		}
	}, [logedInUser?.company?.verified_by_perla]);

	const logoutProcess = useCallback(() => {
		setLoading(true);
		LogoutService(logedInUser.token).then(({ data, status }) => {
			dispatch(resetLogedInUserState());
			dispatch(resetAllSearch());
			localStorage.clear();
			Cookies.remove('token', {
				domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
			});
			window.location.href = process.env.REACT_APP_PERLA_LINK || '';
			setLoading(false);
		});
	}, [dispatch, logedInUser.token]);
	return (
		<div className="app">
			<MuiThemeProvider theme={materialTheme}>
				<Router>
					{window.location.pathname.startsWith('/employee-document/') ? (
						<div className="app__header">
							<img src={perlaLogo} alt="Perla" />
						</div>
					) : (
						<Header logoutProccess={logoutProcess} logedInUser={logedInUser} />
					)}
					{loading ? (
						<RouteLoader />
					) : (
						<Suspense fallback={<RouteLoader />}>
							<Switch>
								{routes.map((route, index) => (
									<Route
										key={index}
										exact={route.exact ?? false}
										path={route.path}
										component={
											checkRoleAccess(logedInUser, route.roles)
												? route.component
												: undefined
										}
										render={
											checkRoleAccess(logedInUser, route.roles)
												? undefined
												: () => <Redirect to="/" />
										}
									/>
								))}
							</Switch>
						</Suspense>
					)}
					<Footer />
				</Router>
				<ToastContainer />
			</MuiThemeProvider>
		</div>
	);
}

export default App;
