import { UserRole } from 'src/constants';

function createEnumChecker<
	T extends string,
	TEnumValue extends number | string
>(
	enumVariable: {
		[key in T]: TEnumValue;
	},
) {
	const enumValues = Object.values(enumVariable);
	return (value: number | string): value is TEnumValue =>
		enumValues.includes(value);
}

export const isUserRole = createEnumChecker(UserRole);
