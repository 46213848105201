export const toBase64String = (object: Record<string, any>) => {
	const jsonString = JSON.stringify(object);

	const base64String = btoa(jsonString);
	return base64String;
};

export const fromBase64String = (base64String: string): Record<string, any> => {
	const jsonString = atob(base64String);

	const object = JSON.parse(jsonString);

	return object;
};
