import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetNotificationsOfLocationService: (
	token: string,
	locationId: string,
	type?: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, locationId: string, type: string = '') => {
	return fetchService({
		url:
			Apis.NOTIFICATIONS_OF_LOCATION +
			'?locationId=' +
			locationId +
			'&type=' +
			type,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetNotificationsOfFolderService: (
	token: string,
	folderId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, folderId: string) => {
	return fetchService({
		url: Apis.NOTIFICATIONS_OF_FOLDER + '?folderId=' + folderId,

		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetCompanyNotificationsService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string) => {
	return fetchService({
		url: Apis.NOTIFICATIONS_OF_COMPANY,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const CompleteActionService: (
	token: string,
	actionId: string,
) => Promise<{ data: any; status: number }> = async (token, actionId) => {
	return fetchService({
		url: `${Apis.COMPLETE_ACTION}?actionId=${actionId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

// TODO: Old logic according to ARCHIVE action, need to be removed in the future
const DeleteActionService: (
	token: string,
	actionId: string,
	softDelete?: boolean,
) => Promise<{ data: any; status: number }> = async (
	token,
	actionId,
	softDelete = true,
) => {
	return fetchService({
		url: `${Apis.SOFT_DELETE_ACTION}?actionId=${actionId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
		body: {
			softDelete,
		},
	});
};

const VoidActionService: (
	token: string,
	actionId: string,
) => Promise<{ data: any; status: number }> = async (token, actionId) => {
	return fetchService({
		url: `${Apis.VOID_ACTION}?actionId=${actionId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

// TODO: Old logic according to UNARCHIVE action, need to be removed in the future
const RestoreDeletedActionService: (
	token: string,
	actionId: string,
) => Promise<{ data: any; status: number }> = async (token, actionId) => {
	return fetchService({
		url: `${Apis.RESTORE_DELETED_ACTION}?actionId=${actionId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	CompleteActionService,
	DeleteActionService,
	GetCompanyNotificationsService,
	GetNotificationsOfFolderService,
	GetNotificationsOfLocationService,
	RestoreDeletedActionService,
	VoidActionService,
};
