import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetAllEquipmentsService: (
	token: string,
	folderId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, folderId: string, ) => {
	return fetchService({
		url: `${Apis.EQUIPMENTS}?folderId=${folderId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};
const GetEquipmentService: (
	token: string,
	equipmentId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, equipmentId: string) => {
	return fetchService({
		url: `${Apis.EQUIPMENT}?equipmentId=${equipmentId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const AddEquipmentService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_EQUIPMENT,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const AddMultipleEquipmentsService: (
	token: string,
	data: FormData,
) => Promise<{ data: any; status: number }> = async (token, data) => {
	return fetchService({
		url: Apis.MULTIPLE_EQUIPMENTS,
		method: 'POST',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
		formData: data,
	});
};

const UpdateEquipmentService: (
	token: string,
	equipmentId: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (token,equipmentId, body) => {
	return fetchService({
		url: `${Apis.UPDATE_EQUIPMENT}/${equipmentId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const DeleteEquipmentService: (
	token: string,
	equipmentId: string,
) => Promise<{ data: any; status: number }> = async (token, equipmentId) => {
	return fetchService({
		url: `${Apis.DELETE_EQUIPMENT}/${equipmentId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	GetAllEquipmentsService,
	AddEquipmentService,
	DeleteEquipmentService,
	GetEquipmentService,
	UpdateEquipmentService,
	AddMultipleEquipmentsService
};
