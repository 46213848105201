import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const SigninService: (body: {
	code: string;
	profileViews: string[];
	redirect_uri: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.SIGNIN,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
	});
};

const LogoutService: (
	token: string,
) => Promise<{ data: any; status: number }> = async token => {
	return fetchService({
		url: Apis.LOGOUT,
		method: 'POST',
		addContentTypeApplicationJson: true,
		token: token,
	});
};

const SignupWithEmailService: (body: {
	first_name: string;
	last_name: string;
	email: string;
	password: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.SIGNUP_WITH_EMAIL,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
	});
};

const VerifyCodeService: (body: {
	userId: string;
	type?: string;
	code: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.VERIFY_CODE,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
	});
};

const ResendCodeService: (body: {
	userId: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.RESEND_CODE,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: true,
	});
};

const SigninWithEmailService: (body: {
	email: string;
	password: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.SIGNIN_EMAIL,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
	});
};

const ForgetPasswordService: (body: {
	email: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.FORGET_PASSWORD,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
	});
};

const ChangePasswordService: (body: {
	password: string;
	operationId: string;
}) => Promise<{ data: any; status: number }> = async body => {
	return fetchService({
		url: Apis.CHANGE_PASSWORD,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
	});
};

export {
	SigninService,
	LogoutService,
	SignupWithEmailService,
	VerifyCodeService,
	ResendCodeService,
	SigninWithEmailService,
	ForgetPasswordService,
	ChangePasswordService,
};
